import {SupportedLocales} from "../app/shared/constants/supported-locales";

export const environment = {
  production: false,
  linkAndroidApp: 'https://play.google.com/store/apps/details?id=com.uventex.studio',
  linkIOSApp: 'https://apps.apple.com/app/myuventex-studio-management/id1465602154',
  //PRIVACY_POLICY_LINK: 'http://devuventex.com/GWTClient/html/privacyPolicy.html',
  PRIVACY_POLICY_LINK: new Map([
    [
      SupportedLocales.en,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.en.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.es,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.es.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.fr,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.fr.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ja,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ja.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ru,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ru.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ua,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ua.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
  ]),
  contactPhoneNumber: '+1 469-766-2095',
  contactEmail: 'support@uventex.com',
  studioApi: 'https://api.studio.demouventex.com/',
  studioPortalUrl: 'https://demouventex.com/studios/',
  STACK_PAY_ENV: 'sandbox',
  APIURL: 'https://sandbox-api.uventex.com/',
  BASEURL: 'https://demouventex.com/',
  EVENT_ADMIN_URL: 'https://demouventex.com/',
  FORTE_JS: '',
  googleRecapchaKey: '6LeKMG0aAAAAACeQrosUaX45lQos1RKG2sdQHfng',
  YOUTUBE: 'https://www.googleapis.com/',
  youtubeChannelId: 'UULF5pnyfSfNG6altM86pxtB3w',
  youtubeAPIKEY: 'AIzaSyCUKVYr1f48g08KJyCciqwTS6HQkhVl2fk',
  devUvLink: 'https://devuventex.com/'
};
